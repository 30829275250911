<template>
  <div class="item">
    <div class="top" :class="data.change ? 'img-change-top' : ''">
      <div v-for="(item, index) in data.top" :key="index" :class="item.type">
        <span v-if="item.type == 'text'">{{ item.value }}</span>
        <img :src="item.value" alt="" v-if="item.type == 'img'" />
        <img
          :src="imgChangeValue == item.key ? item.changeValue : item.value"
          alt=""
          v-if="item.type == 'img-change'"
          @click="imgChange(item.key)"
        />
      </div>
    </div>
    <div class="uploader" v-if="data.type == 'upload'">
      <el-upload
        :class="{ hide: hideUploadEdit }"
        :action="uploadAction"
        :on-success="handleUploadSuccess"
        :on-remove="handleUploadRemove"
        :list-type="uploadListType"
        :limit="limit"
        ref="upload"
      >
        <img
          class="upload-img"
          src="../assets/images/College/upload-button-bg.png"
          alt=""
        />
        <div
          class="upload-text"
          :style="{ position: uploadListType == 'text' ? 'unset' : '' }"
        >
          {{ data.uploadText }}
        </div>
      </el-upload>
    </div>
    <img
      class="center-img"
      :src="data.center.img"
      alt=""
      v-else-if="data.center.type == 'img'"
    />
    <div class="cneter-img cneter-text" v-else-if="data.center.type == 'text'">
      <div class="title">{{ data.center.data.name }}</div>
      <div class="content">
        <div class="item-title">
          一.画图文案如下：（请画师认真阅读文案，再创意）
        </div>
        <div class="item-text">{{ data.center.data.content }}</div>
        <div class="item-title">二.绘画风格参考</div>
        <div class="item-img">
          <div
            class="item-img-div"
            v-for="(img, index) in data.center.data.images"
            :key="index"
          >
            <img :preview="data.center.data.id" :src="img" alt="" />
          </div>
        </div>
        <div class="item-title">三.测试图尺寸</div>
        <div class="item-text">
          高{{ data.center.data.size_json.height }}*宽{{
            data.center.data.size_json.width
          }}
          cm
        </div>
        <div class="item-title">四.测试时间</div>
        <div class="item-text">{{ data.center.data.time_text }} 截止</div>
        <div class="item-right-text">
          测试起始时间{{ data.center.data.createtime_text }}
        </div>
      </div>
    </div>
    <div class="list-items" v-else-if="data.type == 'list'">
      <div class="title">
        <div
          class="title-text"
          :class="listType == 0 ? 'checked' : ''"
          @click="listTypeChange(0)"
        >
          未接收
        </div>
        <div
          class="title-text"
          :class="listType == 1 ? 'checked' : ''"
          @click="listTypeChange(1)"
        >
          已接收
        </div>
      </div>
      <div v-if="listType == 0" v-loading="data.loading">
        <div v-if="!data.loading && !data.list.length" class="no-data">
          暂无数据
        </div>
        <div class="group" v-else>
          <div
            class="group-item"
            v-for="item in data.list"
            :key="item.id"
            @click="item.select = !item.select"
          >
            <img
              class="select"
              :src="
                item.select
                  ? require('../assets/images/communication/select-checked.png')
                  : require('../assets/images/communication/select.png')
              "
              alt=""
            />
            <div class="list-img">
              <img :src="item.img" alt="" />
            </div>
            <div class="list-name">{{ item.name }}</div>
          </div>
          <div class="group-item"></div>
        </div>
      </div>
      <div v-if="listType == 1" v-loading="data.loading">
        <div style="width: 100%">
          <el-input
            placeholder="请输入内容"
            v-model="search"
            size="mini"
            class="input-with-select"
            @input="listTypeChange(1)"
          >
            <template slot="prepend">
              <div class="input-prepend">
                <img src="../assets/images/communication/search.png" alt="" />
              </div>
            </template>
          </el-input>
        </div>
        <div v-if="!data.loading && !data.list.length" class="no-data">
          暂无数据
        </div>
        <div class="group" style="height: 17vw" v-else>
          <div class="group-item-warp" v-for="list in data.list" :key="list.id">
            <div class="time">{{ list.time }}</div>
            <div class="group-items">
              <div
                class="group-item"
                v-for="item in list.list"
                :key="item.id"
                @click="item.select = !item.select"
              >
                <img
                  class="select"
                  :src="
                    item.select
                      ? require('../assets/images/communication/select-checked.png')
                      : require('../assets/images/communication/select.png')
                  "
                  alt=""
                />
                <div class="list-img">
                  <img :src="item.img" alt="" />
                </div>
                <div class="list-name">{{ item.name }}</div>
              </div>
            </div>
            <div class="group-item"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="buttons">
      <span class="have-received" v-if="data.type == 'img'">已接收</span>
      <img
        class="button-img"
        :src="data.buttonImg"
        alt=""
        @click="$emit('buttonImgClick')"
        v-else
      />

      <div class="check-all" @click="checkAll" v-if="data.type == 'list'">
        <img
          class="all-img"
          :src="
            isCheckAll
              ? require('../assets/images/communication/check-all.png')
              : require('../assets/images/communication/check.png')
          "
          alt=""
        />
        <div class="all-text">全选</div>
      </div>
    </div>
    <slot name="confirmUploadSlot"></slot>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "OrderView",
  props: {
    data: Object,
    uploadListType: {
      type: String,
      default: () => {
        return "picture-card";
      },
    },
    limit: {
      type: Number,
      default: () => {
        return 1;
      },
    },
  },
  data() {
    return {
      search: "",
      listType: 0,
      imageUrl: "",
      isCheckAll: false,
      uploadAction: "",
      hideUploadEdit: false, // 是否隐藏上传按钮
      image: "",
      images: [],
      imgChangeValue: 1,
    };
  },
  watch: {
    "data.list": {
      immediate: true,
      deep: true,
      handler(newVal) {
        if (newVal) {
          let listLength = 0,
            selectLength = 0;
          if (this.listType == 1) {
            newVal.forEach((list) => {
              list.list.forEach((item) => {
                listLength++;
                if (item.select) {
                  selectLength++;
                }
              });
            });
            if (listLength == selectLength) {
              this.isCheckAll = true;
            } else {
              this.isCheckAll = false;
            }
          } else {
            let selectArr = newVal.filter((item) => item.select);
            if (selectArr.length == this.data.list.length) {
              this.isCheckAll = true;
            } else {
              this.isCheckAll = false;
            }
          }
        }
      },
    },
  },
  mounted() {
    this.uploadAction = axios.defaults.baseURL + "api/common/upload";
  },
  methods: {
    // 文件上传成功
    handleUploadSuccess(res) {
      if (res.code == 0) {
        this.$refs.upload.clearFiles();
        this.images = [];
        this.$message.error(res.msg);
        return;
      }
      if (this.limit && this.limit > 1) {
        this.images.push(res.data.url);
      } else {
        this.image = res.data.url;
        this.hideUploadEdit = true;
      }
    },
    handleUploadRemove(file, fileList) {
      if (this.limit && this.limit > 1) {
        this.images = [];
        fileList.forEach((file) => {
          let { url } = file.response.data;
          this.images.push(url);
        });
      } else {
        this.image = "";
        setTimeout(() => {
          this.hideUploadEdit = false;
        }, 500);
      }
    },
    // 全选
    checkAll() {
      if (this.listType == 1) {
        this.data.list.forEach((list) => {
          list.list.map((item) => {
            item.select = !this.isCheckAll;
          });
        });
      } else {
        this.data.list.map((item) => {
          item.select = !this.isCheckAll;
        });
      }
      this.isCheckAll = !this.isCheckAll;
    },
    // 切换接收类型
    listTypeChange(index) {
      this.listType = index;
      this.$emit("listTypeChange", {
        listType: index,
        imgType: this.imgChangeValue,
      });
    },
    imgChange(index) {
      this.imgChangeValue = index;
      this.$emit("listTypeChange", { listType: this.listType, imgType: index });
    },
  },
};
</script>
<style>
.hide .el-upload-list--picture-card .el-upload-list__item {
  width: 100%;
  height: 100%;
}
.hide .el-upload--picture-card,
.hide .el-upload--text {
  display: none;
}
.el-upload-list__item-name {
  max-width: 15vw;
}
</style>
<style lang="less" scoped>
.item {
  width: 100%;
  min-height: 29vw;
  background: url(../assets/images/painter/tabke-orders-img-bg1.png) no-repeat;
  background-size: 100% 100%;
  padding: 3% 2%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  &:nth-child(2n) {
    margin-left: 5%;
  }
  .img-change-top {
    justify-content: space-around;
  }
  .top {
    display: flex;
    align-items: center;
    .text {
      font-size: 11px;
      font-family: FZHTJW;
      font-weight: normal;
      color: #ffffff;
      white-space: nowrap;
    }
    .img {
      margin: 0 2%;
      width: 5vw;
      img {
        width: 100%;
      }
    }
    .img-change {
      cursor: pointer;
      margin: 0 2%;
      width: 5vw;
      img {
        width: 100%;
      }
    }
  }
  .center-img {
    width: 100%;
    margin: 2% 0;
  }
  .uploader {
    padding: 3% 5%;
    border-radius: 4px;
    .upload-text {
      font-size: 10px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #ffffff;
      position: absolute;
      top: 2vw;
      left: 0;
      width: 100%;
    }
  }
  .button-img {
    width: 20%;
    cursor: pointer;
    margin: 0 auto;
  }
  .have-received {
    font-size: 1.5vw;
    font-family: Adobe Heiti Std;
    font-weight: normal;
    color: #faec45;
    margin: 0 auto;
  }

  .cneter-text {
    height: 100%;
    background: #fff;
    height: 25vw;
    overflow: auto;
    margin: 0.5vw 0;
    padding: 1vw;
    .title {
      font-size: 1.2vw;
      font-family: Adobe Heiti Std;
      font-weight: normal;
      color: #222222;
      margin: 1vw 0;
    }
    .content {
      text-align: left;
      .item-title {
        font-size: 1.1vw;
        font-family: Adobe Heiti Std;
        font-weight: normal;
        color: #222222;
      }
      .item-img {
        display: flex;
        .item-img-div {
          width: 50%;
          img {
            width: 100%;
          }
        }
      }
      .item-text {
        font-size: 1vw;
        font-family: Adobe Heiti Std;
        font-weight: normal;
        color: #555555;
        padding: 1vw;
      }
      .item-right-text {
        text-align: right;
        font-size: 1vw;
        font-family: Adobe Heiti Std;
        font-weight: normal;
        color: #656565;
      }
    }
  }
  .list-items {
    flex: 1;
    margin-bottom: 5%;
    .title {
      display: flex;
      justify-content: space-evenly;
      margin: 5% 0;
      &-text {
        border: 1px solid #fbee59;
        font-family: Adobe Heiti Std;
        font-weight: normal;
        color: #fff;
        font-size: 1vw;
        padding: 1% 2%;
        cursor: pointer;
      }
      .checked {
        color: #000;
        background: #fbee59;
      }
    }
    .group-item-warp {
      padding-bottom: 5%;
      width: 100%;
      .time {
        font-size: 7px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #ffffff;
        text-align: left;
        margin-bottom: 2%;
      }
      .group-items {
        height: 100%;
      }
    }
    .group,
    .group-items {
      display: flex;
      flex-wrap: wrap;
      height: 20vw;
      overflow: auto;
      .no-data {
        width: 100%;
      }
      &::-webkit-scrollbar {
        display: none;
      }
      .input-with-select {
        margin-bottom: 2%;
        .input-prepend {
          display: flex;
        }
      }
      .group-item {
        width: 30%;
        height: 50%;
        margin-bottom: 2%;
        position: relative;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        &:nth-child(3n - 1) {
          margin: 0 5%;
        }
        .select {
          position: absolute;
          right: 0.2vw;
          top: 0.2vw;
        }
        .list-img {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            max-width: 100%;
          }
        }
        .list-name {
          font-size: 7px;
          font-family: Adobe Heiti Std;
          font-weight: normal;
          color: #ffffff;
          margin: 2% 0;
        }
      }
    }
  }
  .buttons {
    display: flex;
    position: relative;
    .check-all {
      display: flex;
      position: absolute;
      right: 0;
      bottom: 0.3vw;
      align-items: center;
      cursor: pointer;
      .all-img {
        width: 1vw;
        height: 1vw;
        margin-right: 10px;
      }
      .all-text {
        font-size: 10px;
        font-family: Adobe Heiti Std;
        font-weight: normal;
        color: #ffffff;
      }
    }
  }
}
</style>
